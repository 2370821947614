import { GetAction, PostAction, UpdateAction } from "@/store/actionHelper";

// [MEMBERSHIP ACTIONS]
export const CREATE_MEMBERSHIP = 'createMembership';
export const UPDATE_MEMBERSHIP = 'updateMembership';
export const GET_MEMBERSHIP_LIST = 'getMembershipList';
export const GET_MEMBERSHIP_CONFIG = 'getMembershipConfig';
export const FIND_MEMBERSHIP = 'findMembership';
export const SWITCH_MEMBERSHIP_STATE = 'switchMembershipState';
// [MEMBERSHIP MUTATIONS]
export const SET_MEMBERSHIP = 'setMembership';
export const SET_MEMBERSHIP_LIST = 'setMembershipList';
export const SET_MEMBERSHIP_ERROR = 'setMembershipError';

const state = {
  membership: {},
  membershipList: [],
  membershipListMeta: {},
  membershipError: null
};

const getters = {
  membership: state=> state.membership,
  membershipList: state=> state.membershipList,
  membershipListMeta: state=> state.membershipListMeta,
  membershipError: state=> state.membershipError,
};

const mutations = {
  [SET_MEMBERSHIP](state, payload){
    state.membership = payload;
  },
  [SET_MEMBERSHIP_LIST](state, payload){
    let { data, meta } = payload;
    state.membershipList = data;
    state.membershipListMeta = meta;
  },
  [SET_MEMBERSHIP_ERROR](state, payload){
    state.membershipError = payload;
  }
};

const actions = {
  [CREATE_MEMBERSHIP](context, formData){
    return PostAction(
    'manager/memberships',
      formData, context, SET_MEMBERSHIP_ERROR,
      { credentials: true, resolved: ( context, response ) => { context.commit( SET_MEMBERSHIP, response.data );} }
    );
  },
  [UPDATE_MEMBERSHIP](context, formData){
    return UpdateAction(
    'manager/memberships', formData.id, formData, context, SET_MEMBERSHIP_ERROR,
    { credentials: true, resolved: ( context, response ) => { context.commit( SET_MEMBERSHIP, response.data );} }
    );
  },
  [GET_MEMBERSHIP_LIST](context, { page = 1, title = '' }){
    return GetAction( 'manager/memberships',
    `?page=${page || 1}&title=${title || ''}`,
      context,
      SET_MEMBERSHIP_ERROR,
      {
        credentials: true,
        resolved: ( context, response ) => {
          context.commit( SET_MEMBERSHIP_LIST, response.data );
        }
      }
    );
  },
  [FIND_MEMBERSHIP](context, id){
    return GetAction( 'manager/memberships',
    `${id}`,
    context,
    SET_MEMBERSHIP_ERROR,
    {
      credentials: true,
      resolved: ( context, response ) => {
        context.commit( SET_MEMBERSHIP, response.data );
      }
    }
    );
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
