export const ID_TOKEN_KEY = "id_token";

// [USER ACTIONS]
export const GET_USER_LIST = 'getUserList'; // 获取用户列表

// [SETTING ACTIONS]
export const FIND_SETTING = 'findSetting'; // 查找ID对应的配置项
export const GET_SETTING_LIST = 'getSettingList'; // 获取所有的配置项
export const UPDATE_SETTING = 'updateSetting'; // 更新一个配置项
export const UPDATE_SETTING_LIST = 'updateSettingList'; // 更新所有的配置项
export const DELETE_SETTING = 'deleteSetting'; // 删除一个配置项
// [SETTING MUTATIONS]
export const SET_SETTING = 'setSetting'; // 设置一个setting state
export const SET_SETTING_LIST = 'setSettingList'; // 设置一个settingList state
