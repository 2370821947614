import { GetAction, PostAction, UpdateAction } from "../actionHelper";

// [NAVIGATION ACTIONS]
export const GET_REGION = 'getRegion'; //
export const GET_REGIONS = 'getRegions'; //
export const STORE_REGION = 'storeRegion'; //
export const UPDATE_REGION = 'updateRegion'; //
export const SEARCH_REGION = 'searchRegion'; //

// [NAVIGATION MUTATIONS]
export const SET_REGION = 'setRegion'; //
export const SET_REGIONS = 'setRegions'; //
export const SET_REGION_ERROR = 'setRegionError'; //
export const SET_SEARCH_REGION_LIST = 'setSearchRegionList'; //

const state = {
  region: {},
  regions: [],
  regionsMeta: {},
  regionError: null,
  searchRegionList: [],
  searchRegionListMeta: {}
};

const mutations = {
  [SET_REGION](state, payload){
    state.region = payload;
  },
  [SET_REGIONS](state, payload){
    state.regions = payload.data;
    state.regionsMeta = payload.meta;
  },
  [SET_REGION_ERROR](state, payload){
    state.regionError = payload;
  },
  [SET_SEARCH_REGION_LIST](state, payload){
    state.searchRegionList = payload.data;
    state.searchRegionListMeta = payload.meta || {};
  },
};

const actions = {
  [GET_REGION]( context, id ) {
    return GetAction( `manager/films/regions/${id}`,
    ``,
    context,
    SET_REGION_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_REGION, response.data );
      }
    } );
  },
  [GET_REGIONS]( context, { page= 1, title= '' } ) {
    return GetAction( "manager/films/regions",
    `?title=${title || ''}&page=${page || 1}`,
    context,
    SET_REGION_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_REGIONS, response.data );
      }
    } );
  },
  [STORE_REGION]( context, payload = {} ) {
    return PostAction(
    "manager/films/regions", payload, context, SET_REGION_ERROR,
    { credentials: true }
    );
  },
  [UPDATE_REGION]( context, payload = {} ) {
    return UpdateAction(
    "manager/films/regions", payload.id, payload, context, SET_REGION_ERROR,
    { credentials: true, resolved: ( context, response ) => { context.commit( SET_REGION, response.data );} }
    );
  },
  [SEARCH_REGION]( context, { title = '', page = false } ) {
    return GetAction( "manager/films/regions/search",
    `?title=${title || ''}${page ? '&page=' + page : ''}${page ? '&paginate=' + page : ''}`,
    context,
    SET_REGION_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        let payload = page ? response.data : response;
        context.commit( SET_SEARCH_REGION_LIST, payload );
      }
    } );
  },
};

const getters = {
  region: state=> state.region,
  regions: state=> state.regions,
  regionsMeta: state=> state.regionsMeta,
  regionError: state=> state.regionError,
  searchRegionList: state=> state.searchRegionList,
  searchRegionListMeta: state=> state.searchRegionListMeta,
};

export default {
  state,
  mutations,
  actions,
  getters
}
