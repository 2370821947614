import { PostAction } from "../../actionHelper";

// [DOMAIN TO IP ACTIONS]
export const DOMAIN_TO_IP = 'domainToIP'; //


const state = {
};

const mutations = {
};

const actions = {
  [DOMAIN_TO_IP]( context, { url, mutationType = null } ) {
    return PostAction( `manager/other/domain2ip`,
    { url },
    context,
    mutationType, { credentials: true } );
  },
};

const getters = {
};

export default {
  state,
  mutations,
  actions,
  getters
}
