import { GetAction, PostAction, UpdateAction } from "../actionHelper";

// [NAVIGATION ACTIONS]
export const GET_LANGUAGE = 'getLanguage'; //
export const GET_LANGUAGES = 'getLanguages'; //
export const STORE_LANGUAGE = 'storeLanguage'; //
export const UPDATE_LANGUAGE = 'updateLanguage'; //
export const SEARCH_LANGUAGE = 'searchLanguage'; //

// [NAVIGATION MUTATIONS]
export const SET_LANGUAGE = 'setLanguage'; //
export const SET_LANGUAGES = 'setLanguages'; //
export const SET_LANGUAGE_ERROR = 'setLanguageError'; //
export const SET_SEARCH_LANGUAGE_LIST = 'setSearchLanguageList'; //

const state = {
  language: {},
  languages: [],
  languagesMeta: {},
  languageError: null,
  searchLanguageList: [],
  searchLanguageListMeta: {}
};

const mutations = {
  [SET_LANGUAGE](state, payload){
    state.language = payload;
  },
  [SET_LANGUAGES](state, payload){
    state.languages = payload.data;
    state.languagesMeta = payload.meta;
  },
  [SET_LANGUAGE_ERROR](state, payload){
    state.languageError = payload;
  },
  [SET_SEARCH_LANGUAGE_LIST](state, payload){
    state.searchLanguageList = payload.data;
    state.searchLanguageListMeta = payload.meta || {};
  },
};

const actions = {
  [GET_LANGUAGE]( context, id ) {
    return GetAction( `manager/films/languages/${id}`,
    ``,
    context,
    SET_LANGUAGE_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_LANGUAGE, response.data );
      }
    } );
  },
  [GET_LANGUAGES]( context, { page= 1, title= '' } ) {
    return GetAction( "manager/films/languages",
    `?title=${title || ''}&page=${page || 1}`,
    context,
    SET_LANGUAGE_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_LANGUAGES, response.data );
      }
    } );
  },
  [STORE_LANGUAGE]( context, payload = {} ) {
    return PostAction(
    "manager/films/languages", payload, context, SET_LANGUAGE_ERROR,
    { credentials: true }
    );
  },
  [UPDATE_LANGUAGE]( context, payload = {} ) {
    return UpdateAction(
    "manager/films/languages", payload.id, payload, context, SET_LANGUAGE_ERROR,
    { credentials: true, resolved: ( context, response ) => { context.commit( SET_LANGUAGE, response.data );} }
    );
  },
  [SEARCH_LANGUAGE]( context, { title = '', page = false } ) {
    return GetAction( "manager/films/languages/search",
    `?title=${title || ''}${page ? '&page=' + page : ''}${page ? '&paginate=' + page : ''}`,
    context,
    SET_LANGUAGE_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        let payload = page ? response.data : response;
        context.commit( SET_SEARCH_LANGUAGE_LIST, payload );
      }
    } );
  },
};

const getters = {
  language: state=> state.language,
  languages: state=> state.languages,
  languagesMeta: state=> state.languagesMeta,
  languageError: state=> state.languageError,
  searchLanguageList: state=> state.searchLanguageList,
  searchLanguageListMeta: state=> state.searchLanguageListMeta,
};

export default {
  state,
  mutations,
  actions,
  getters
}
