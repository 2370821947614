import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import LC from "../common/storage/localStorage";
import { ID_TOKEN_KEY } from "@/store/types";
// import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "../store/modules/htmlclass.module";
import { LOGOUT, PURGE_AUTH, VERIFY_AUTH } from "@/store/modules/auth.module";
import { debugConsole } from "@c@/helpers/helpers";

const router = new VueRouter( {
  routes,
  scrollBehavior( to, from, savedPosition ) {
    if ( savedPosition ) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
} );

Vue.use( VueRouter );

// Ensure we checked auth before each page load.
router.beforeEach( ( to, from, next ) => {
  debugConsole({ prefix: 'router', message: 1});
  if ( to.meta.requiresAuth ) {
    debugConsole({ prefix: 'router', message: 2});
    // 授权码还没过期
    if ( !LC.hasExpired( ID_TOKEN_KEY ) ) {
      debugConsole({ prefix: 'router', message: 3});
      Promise.all([ router.app.$options.store.dispatch(VERIFY_AUTH, router) ])
      .then(()=>{
        debugConsole({ prefix: 'router', message: 4});
        next();
      })
      // 授权码已经过期了
    } else if ( !LC.has( ID_TOKEN_KEY ) ) {
      debugConsole({ prefix: 'router', message: 5});
      if ( from.name !== 'login' ) {
        debugConsole({ prefix: 'router', message: 6});
        router.app.$options.store.dispatch( LOGOUT )
          .then( () => {
            debugConsole({ prefix: 'router', message: 7});
            return next({ name: 'login' });
          } );
        debugConsole({ prefix: 'router', message: 8});
        // next({ name: 'login' });
      }
      debugConsole({ prefix: 'router', message: 9});
      return next();
    }else{
      debugConsole({ prefix: 'router', message: 10});
      return next({ name: 'login' });
    }
  } else if ( to.meta.requiresGuest ) {
    debugConsole({ prefix: 'router', message: 11});
    // 不需要登录的页面存在授权码时跳转到首页
    if ( !LC.hasExpired( ID_TOKEN_KEY ) ) {
      debugConsole({ prefix: 'router', message: 12});
      next( { name: 'dashboard' } );
    } else {
      debugConsole({ prefix: 'router', message: 13});
      next();
    }
  } else {
    debugConsole({ prefix: 'router', message: 14});
    next();
  }
  debugConsole({ prefix: 'router', message: 15});

  // Scroll page to top on every route change
  setTimeout( () => {
    window.scrollTo( 0, 0 );
  }, 100 );
} );

router.afterEach((to, from) => {
  let { title = 'باشقۇرۇش مەركىزى' } = to.meta;
  if ( title ){
    document.title = title;
  }
})

export default router;
