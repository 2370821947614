import { PutAction, DeleteAction } from "@/store/actionHelper";

// [COPY ACTIONS]
export const SWITCH_COLUMN = 'switchColumn';
export const DELETE_MODEL = 'deleteModel';

const actions = {
  [SWITCH_COLUMN](context, {resource = null, id, column = 'is_show', mutationType}){
    return PutAction(
    `manager/${resource}/${id}/switch`, {column}, context, mutationType,
      { credentials: true }
    );
  },
  [DELETE_MODEL](context, {resource = null, id, mutationType}){
    return DeleteAction(
    `manager/${resource}/${id}`, context, mutationType,
      { credentials: true }
    );
  },
};

export default {
  actions
}
