import { GetAction, PostAction, UpdateAction } from "../actionHelper";

// [NAVIGATION ACTIONS]
export const GET_NAVIGATIONS = 'getNavigations'; //
export const GET_NAVIGATION = 'getNavigation'; //
export const STORE_NAVIGATION = 'storeNavigation'; //
export const UPDATE_NAVIGATION = 'updateNavigation'; //
// [NAVIGATION MUTATIONS]
export const SET_NAVIGATIONS = 'setNavigations'; //
export const SET_NAVIGATION = 'setNavigation'; //
export const SET_NAVIGATION_ERROR = 'setRegionError'; //

const state = {
  navigation: {},
  navigations: [],
  navigationsMeta: {},
  navigationError: null
};

const mutations = {
  [SET_NAVIGATION_ERROR]( state, error ) {
    state.navigationError = error;
  },
  [SET_NAVIGATIONS]( state, payload ) {
    state.navigations = payload.data;
    state.navigationsMeta = payload.meta;
  },
  [SET_NAVIGATION]( state, navigation ) {
    state.navigation = navigation;
  }
};

const actions = {
  [GET_NAVIGATIONS]( context, { view_type= 'banner', page= 1, title= '' } ) {
    return GetAction( "manager/navigations",
    `?view_type=${view_type || 'banner'}&title=${title || ''}&page=${page || 1}`,
    context,
    SET_NAVIGATION_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_NAVIGATIONS, response.data );
      }
    } );
  },
  [GET_NAVIGATION]( context, id ) {
    return GetAction( `manager/navigations/${id}`,
    ``,
    context,
    SET_NAVIGATION_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_NAVIGATION, response.data );
      }
    } );
  },
  [STORE_NAVIGATION]( context, payload = {} ) {
    return PostAction(
    "manager/navigations", payload, context, SET_NAVIGATION_ERROR,
    { credentials: true }
    );
  },
  [UPDATE_NAVIGATION]( context, payload = {} ) {
    return UpdateAction(
    "manager/navigations", payload.id, payload, context, SET_NAVIGATION_ERROR,
    { credentials: true, resolved: ( context, response ) => { context.commit( SET_NAVIGATION, response.data );} }
    );
  }
};

const getters = {
  navigations: ( state ) => state.navigations, //
  navigationsMeta: ( state ) => state.navigationsMeta, //
};

export default {
  state,
  mutations,
  actions,
  getters
}
