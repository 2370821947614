import { GetAction, PostAction, UpdateAction } from "../actionHelper";

// [PLATFORM ACTIONS]
export const GET_PLATFORM = 'getPlatform'; //
export const GET_PLATFORMS = 'getPlatforms'; //
export const STORE_PLATFORM = 'storePlatform'; //
export const UPDATE_PLATFORM = 'updatePlatform'; //

export const DO_PLATFORM_CONNECTION = 'doPlatformConnection'; //
export const GET_PLATFORM_FILMS = 'getPlatformFilms'; //
export const DO_TRANSFER = 'doTransfer'; //

// [PLATFORM MUTATIONS]
export const SET_PLATFORM = 'setPlatform'; //
export const SET_PLATFORMS = 'setPlatforms'; //
export const SET_PLATFORM_ERROR = 'setPlatformError'; //
export const SET_PLATFORM_FILMS = 'setPlatformFilms'; //
export const RESET_PLATFORM_FILMS = 'resetPlatformFilms'; //

const state = {
  platform: {},
  platforms: [],
  platformsMeta: {},
  platformError: null,

  platformFilms: [],
  platformFilmsMeta: {},
};

const mutations = {
  [SET_PLATFORM](state, payload){
    state.platform = payload;
  },
  [SET_PLATFORMS](state, payload){
    state.platforms = payload.data;
    state.platformsMeta = payload.meta;
  },
  [SET_PLATFORM_ERROR](state, payload){
    state.platformError = payload;
  },

  [SET_PLATFORM_FILMS](state, payload){
    state.platformFilms = payload.data;
    state.platformFilmsMeta = payload.meta;
  },
  [RESET_PLATFORM_FILMS](state, payload){
    state.platformFilms = [];
    state.platformFilmsMeta = {};
  },
};

const actions = {
  [GET_PLATFORM]( context, id ) {
    return GetAction( `manager/platforms/${id}`,
    ``,
    context,
    SET_PLATFORM_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_PLATFORM, response.data );
      }
    } );
  },
  [GET_PLATFORMS]( context, { page= 1, title= '' } ) {
    return GetAction( "manager/platforms",
    `?title=${title || ''}&page=${page || 1}`,
    context,
    SET_PLATFORM_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_PLATFORMS, response.data );
      }
    } );
  },
  [STORE_PLATFORM]( context, payload = {} ) {
    return PostAction(
    "manager/platforms", payload, context, SET_PLATFORM_ERROR,
    { credentials: true }
    );
  },
  [UPDATE_PLATFORM]( context, payload = {} ) {
    return UpdateAction(
    "manager/platforms", payload.id, payload, context, SET_PLATFORM_ERROR,
    { credentials: true, resolved: ( context, response ) => { context.commit( SET_PLATFORM, response.data );} }
    );
  },

  [DO_PLATFORM_CONNECTION]( context, id ) {
    return GetAction( `manager/platforms`,
    `${id}/connection`,
    context,
    SET_PLATFORM_ERROR, {credentials: true}
    );
  },
  [GET_PLATFORM_FILMS]( context, { id, page= 1, title= '' } ) {
    return GetAction( `manager/platforms/${id}/platform-home?title=${title || ''}&page=${page || 1}`,
    ``,
    context,
    SET_PLATFORM_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_PLATFORM_FILMS, response.data );
      }
    } );
  },
  [DO_TRANSFER]( context, params = {} ) {
    return PostAction( `manager/platforms/store-transfer-tasks`,
    params,
    context,
    SET_PLATFORM_ERROR, {credentials: true}
    );
  },
};

const getters = {
  platform: state=> state.platform,
  platforms: state=> state.platforms,
  platformsMeta: state=> state.platformsMeta,
  platformError: state=> state.platformError,

  platformFilms: state=> state.platformFilms,
  platformFilmsMeta: state=> state.platformFilmsMeta,
};

export default {
  state,
  mutations,
  actions,
  getters
}
