import { GetAction, PostAction, UpdateAction } from "../actionHelper";

// [NAVIGATION ACTIONS]
export const GET_FLAG = 'getFlag'; //
export const GET_FLAGS = 'getFlags'; //
export const STORE_FLAG = 'storeFlag'; //
export const UPDATE_FLAG = 'updateFlag'; //
export const SEARCH_FLAG = 'searchFlag'; //

// [NAVIGATION MUTATIONS]
export const SET_FLAG = 'setFlag'; //
export const SET_FLAGS = 'setFlags'; //
export const SET_FLAG_ERROR = 'setFlagError'; //
export const SET_SEARCH_FLAG_LIST = 'setSearchFlagList'; //

const state = {
  flag: {},
  flags: [],
  flagsMeta: {},
  flagError: null,
  searchFlagList: [],
  searchFlagListMeta: {}
};

const mutations = {
  [SET_FLAG](state, payload){
    state.flag = payload;
  },
  [SET_FLAGS](state, payload){
    state.flags = payload.data;
    state.lflagsMeta = payload.meta;
  },
  [SET_FLAG_ERROR](state, payload){
    state.flagError = payload;
  },
  [SET_SEARCH_FLAG_LIST](state, payload){
    state.searchFlagList = payload.data;
    state.searchFlagListMeta = payload.meta || {};
  },
};

const actions = {
  [GET_FLAG]( context, id ) {
    return GetAction( `manager/films/flags/${id}`,
    ``,
    context,
    SET_FLAG_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_FLAG, response.data );
      }
    } );
  },
  [GET_FLAGS]( context, { page= 1, title= '' } ) {
    return GetAction( "manager/films/flags",
    `?title=${title || ''}&page=${page || 1}`,
    context,
    SET_FLAG_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_FLAGS, response.data );
      }
    } );
  },
  [STORE_FLAG]( context, payload = {} ) {
    return PostAction(
    "manager/films/flags", payload, context, SET_FLAG_ERROR,
    { credentials: true }
    );
  },
  [UPDATE_FLAG]( context, payload = {} ) {
    return UpdateAction(
    "manager/films/flags", payload.id, payload, context, SET_FLAG_ERROR,
    { credentials: true, resolved: ( context, response ) => { context.commit( SET_FLAG, response.data );} }
    );
  },
  [SEARCH_FLAG]( context, { title = '', page = false } ) {
    return GetAction( "manager/films/flags/search",
    `?title=${title || ''}${page ? '&page=' + page : ''}${page ? '&paginate=' + page : ''}`,
    context,
    SET_FLAG_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        let payload = page ? response.data : response;
        context.commit( SET_SEARCH_FLAG_LIST, payload );
      }
    } );
  },
};

const getters = {
  flag: state=> state.flag,
  flags: state=> state.flags,
  flagsMeta: state=> state.flagsMeta,
  flagError: state=> state.flagError,
  searchFlagList: state=> state.searchFlagList,
  searchFlagListMeta: state=> state.searchFlagListMeta,
};

export default {
  state,
  mutations,
  actions,
  getters
}
