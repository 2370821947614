import { GetAction, PostAction, UpdateAction } from "../actionHelper";

// [NAVIGATION ACTIONS]
export const GET_ENTRUST = 'getEntrust'; //
export const GET_ENTRUST_ORDERS = 'getEntrustOrders'; //
export const GET_ENTRUST_STATISTICS = 'getEntrustStatistics'; //
export const GET_ENTRUSTS = 'getEntrusts'; //
export const MANUAL_DELETE_CONTRACT = 'manualDeleteContract'; //

// [NAVIGATION MUTATIONS]
export const SET_ENTRUST = 'setEntrust'; //
export const SET_ENTRUST_ORDERS = 'setEntrustOrders'; //
export const SET_ENTRUST_STATISTICS = 'setEntrustStatistics'; //
export const SET_ENTRUSTS = 'setEntrusts'; //
export const SET_ENTRUST_ERROR = 'setEntrustError'; //

const state = {
  entrust: {},
  entrusts: [],
  entrustsMeta: {},
  entrustError: null,
  entrustStatistics: {},
  entrustOrders: [],
  entrustOrdersMeta: {},
};

const mutations = {
  [SET_ENTRUST](state, payload){
    state.entrust = payload;
  },
  [SET_ENTRUSTS](state, payload){
    state.entrusts = payload.data;
    state.entrustsMeta = payload.meta;
  },
  [SET_ENTRUST_ORDERS](state, payload){
    state.entrustOrders = payload.data;
    state.entrustOrdersMeta = payload.meta;
  },
  [SET_ENTRUST_STATISTICS](state, payload){
    state.entrustStatistics = payload;
  },
  [SET_ENTRUST_ERROR](state, payload){
    state.entrustError = payload;
  },
};

const actions = {
  [GET_ENTRUST]( context, id ) {
    return GetAction( `manager/entrusts/${id}`,
    ``,
    context,
    SET_ENTRUST_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ENTRUST, response.data );
      }
    } );
  },
  [GET_ENTRUST_ORDERS]( context, { id, page = 1 } ) {
    return GetAction( `manager/entrusts`,
    `${id}/orders?page=${page ? page : 1}`,
    context,
    SET_ENTRUST_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ENTRUST_ORDERS, response.data );
      }
    } );
  },
  [GET_ENTRUSTS]( context, { page = 1, start_time= '', end_time= '' } ) {
    return PostAction( "manager/entrusts/list",
    {page, start_time, end_time},
    context,
    SET_ENTRUST_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ENTRUSTS, response.data );
      }
    } );
  },
  [GET_ENTRUST_STATISTICS]( context, { start_time= '', end_time= '' } ) {
    return PostAction( "manager/entrusts/statistics",
    {start_time, end_time},
    context,
    SET_ENTRUST_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ENTRUST_STATISTICS, response.data );
      }
    } );
  },
  [MANUAL_DELETE_CONTRACT]( context, id ) {
    return GetAction( `manager/entrusts/${id}/delete-contract`,
    ``,
    context,
    SET_ENTRUST_ERROR, {credentials: true} );
  },
};

const getters = {
  entrust: state=> state.entrust,
  entrusts: state=> state.entrusts,
  entrustsMeta: state=> state.entrustsMeta,
  entrustError: state=> state.entrustError,
  entrustStatistics: state=> state.entrustStatistics,
  entrustOrders: state=> state.entrustOrders,
  entrustOrdersMeta: state=> state.entrustOrdersMeta,
};

export default {
  state,
  mutations,
  actions,
  getters
}
