import handleReject from "../common/handleReject";
import { SET_AUTH_ERROR } from "./modules/auth.module";
import ApiService from "../common/api.service";

const BaseAction = ({method = 'post', url, params, slug = null, context, mutationType, credentials = false, version = 'v3', resolved = null, rejected = null })=>{
  return new Promise((resolve, reject) => {
    (
      method === 'post' && ApiService.post( url, params, credentials, version ) ||
      method === 'get' && ApiService.get( url, slug, credentials, version ) ||
      method === 'put' && ApiService.put( url, params, credentials, version ) ||
      method === 'update' && ApiService.update( url, slug, params, credentials, version ) ||
      method === 'delete' && ApiService.delete( url, credentials, version )
    )
    .then( response=> {
      mutationType && context.commit( mutationType, null );
      resolve(response);
      typeof resolved === 'function' && resolved(context, response);
    })
    .catch(response => {
      mutationType && handleReject(context, response, mutationType);
      reject(response);
      typeof rejected === 'function' && rejected(context, response);
    });
  })
}

export const PostAction = (url, params, context, mutationType, {credentials = false, version = 'v3', resolved = null, rejected = null})=>{
  return BaseAction({method: 'post', url, params, credentials, version, context, mutationType, resolved, rejected});
}

export const GetAction = (url, slug = '', context, mutationType, {credentials = false, version = 'v3', resolved = null, rejected = null})=>{
  return BaseAction({method: 'get', url, slug, credentials, version, context, mutationType, resolved, rejected});
}

export const PutAction = (url, params, context, mutationType, {credentials = false, version = 'v3', resolved = null, rejected = null})=>{
  return BaseAction({method: 'put', url, params, credentials, version, context, mutationType, resolved, rejected});
}

export const UpdateAction = (url, slug, params, context, mutationType, {credentials = false, version = 'v3', resolved = null, rejected = null})=>{
  return BaseAction({method: 'update', url, slug, params, credentials, version, context, mutationType, resolved, rejected});
}

export const DeleteAction = (url, context, mutationType, {credentials = false, version = 'v3', resolved = null, rejected = null})=>{
  return BaseAction({method: 'delete', url, credentials, version, context, mutationType, resolved, rejected});
}
