import { GetAction, PostAction } from "@/store/actionHelper";

// [WALLET ACTIONS]
export const FIND_WALLET = 'findWallet'; // 查找用户
export const GET_WALLET_LIST = 'getWalletList'; // 获取用户列表
// [WALLET MUTATIONS]
export const SET_WALLET = 'setWallet'; // 设置用户
export const SET_WALLET_LIST = 'setWalletList'; // 设置用户列表
export const SET_WALLET_META = 'setWalletMeta'; // 设置用户分页数据

// [WALLET LOGS ACTIONS]
export const GET_USER_WALLET_LOGS = 'getUserWalletLogs';
// [WALLET LOGS MUTATIONS]
export const SET_USER_WALLET_LOGS = 'setUserWalletLogs';

// [WALLET LOGS ACTIONS]
export const GET_USER_WALLET_STATISTICS = 'getUserWalletStatistics';
// [WALLET LOGS MUTATIONS]
export const SET_USER_WALLET_STATISTICS = 'setUserWalletStatistics';

// [WALLET CASH REQUEST ACTIONS]
export const GET_WALLET_CASH_REQUEST_LIST = 'getWalletCashRequestList'; // 获取提现列表
export const GET_WALLET_CASH_REQUEST_STATISTICS = 'getWalletCashRequestStatistics'; // 获取提现列表统计相关的数据
export const CONFIRM_CASH_REQUEST = 'confirmCashRequest'; // 获取用户列表
// [WALLET CASH REQUEST MUTATIONS]
export const SET_WALLET_CASH_REQUEST = 'setWalletCashRequest'; // 设置用户
export const SET_WALLET_CASH_REQUEST_LIST = 'setWalletCashRequestList'; // 设置提现列表
export const SET_WALLET_CASH_REQUEST_STATISTICS = 'setWalletCashRequestStatistics'; // 设置提现列表统计相关的数据
export const SET_WALLET_ERROR = 'setWalletError';

const state = {
  userWalletLogs: [],
  userWalletLogsMeta: {},

  userWalletStatistics: {},

  cashRequest: {}, // 钱包数据
  cashRequestList: [], // 钱包列表
  cashRequestMeta: {}, // 钱包列表分页相关数据

  cashRequestStatistics: {}, // 提现列表统计相关的数据

  walletError: {}, // 钱包数据发生的错误
};

const mutations = {
  [SET_WALLET_CASH_REQUEST](state, wallet){
    state.cashRequest = wallet;
  },
  [SET_WALLET_CASH_REQUEST_STATISTICS](state, payload){
    state.cashRequestStatistics = payload;
  },
  [SET_WALLET_CASH_REQUEST_LIST](state, payload){
    let { data: cashRequestList, meta } = payload;
    state.cashRequestList = cashRequestList;
    state.cashRequestMeta = meta;
  },
  [SET_WALLET_ERROR](state, errors){
    state.walletError = errors;
  },
  [SET_USER_WALLET_LOGS] (state, payload){
    state.userWalletLogs = payload.data;
    state.userWalletLogsMeta = payload.meta;
  },
  [SET_USER_WALLET_STATISTICS] (state, payload){
    state.userWalletStatistics = payload;
  }
}

const actions = {
  [GET_WALLET_CASH_REQUEST_LIST](context, { page = 1, filter = 0, params = {} }) {
    return GetAction( `manager/users/wallet/cash-requests`,
    `?filter=${filter || 0}&page=${page || 1}&start_time=${params.start_time ? params.start_time : ''}&end_time=${params.end_time ? params.end_time : ''}`,
    context,
    SET_WALLET_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_WALLET_CASH_REQUEST_LIST, response.data );
      }
    } );
  },
  [GET_WALLET_CASH_REQUEST_STATISTICS](context,{params = {}}){
    return GetAction( `manager/users/wallet/cash-statistics`,
    `?start_time=${params.start_time ? params.start_time : ''}&end_time=${params.end_time ? params.end_time : ''}`,
    context,
    SET_WALLET_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_WALLET_CASH_REQUEST_STATISTICS, response.data );
      }
    } );
  },
  [CONFIRM_CASH_REQUEST](context, options) {
    return PostAction( `manager/users/wallet/cash-requests/${options.id}/comply`,
      options,
      context,
    SET_WALLET_ERROR, {credentials: true});
  },

  [GET_USER_WALLET_LOGS](context,{id, page = 1, params = {}}){
    return GetAction( `manager/users/${id}/wallet-logs`,
    `?page=${page}&start_time=${params.start_time ? params.start_time : ''}&end_time=${params.end_time ? params.end_time : ''}`,
    context,
    SET_WALLET_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_USER_WALLET_LOGS, response.data );
      }
    } );
  },
  [GET_USER_WALLET_STATISTICS](context,{id, params = {}}){
    return GetAction( `manager/users/${id}/wallet-statistics`,
    `?start_time=${params.start_time ? params.start_time : ''}&end_time=${params.end_time ? params.end_time : ''}`,
    context,
    SET_WALLET_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_USER_WALLET_STATISTICS, response.data );
      }
    } );
  }
}

const getters = {
  userWalletStatistics: state=> state.userWalletStatistics,
  cashRequestStatistics: state=> state.cashRequestStatistics,
  userWalletLogs: state=> state.userWalletLogs,
  userWalletLogsMeta: state=> state.userWalletLogsMeta,
  cashRequest: state=> state.cashRequest,
  cashRequestList: state=> state.cashRequestList,
  cashRequestMeta: state=> state.cashRequestMeta,
  walletError: state=> state.walletError,
};

export default {
  state,
  mutations,
  actions,
  getters
}
