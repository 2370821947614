import { PostAction } from "../../actionHelper";

// [NAVIGATION ACTIONS]
export const GET_WECHAT_NEWS_INFO = 'getWechatNewsInfo'; //


const state = {
};

const mutations = {
};

const actions = {
  [GET_WECHAT_NEWS_INFO]( context, { url, mutationType = null } ) {
    return PostAction( `manager/other/wechat-news`,
    { url },
    context,
    mutationType, { credentials: true } );
  },
};

const getters = {
};

export default {
  state,
  mutations,
  actions,
  getters
}
