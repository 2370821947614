import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth.module";
import htmlClass from "./modules/htmlclass.module";
import config from "./modules/config.module";
import breadcrumbs from "./modules/breadcrumbs.module";
import navigation from "./modules/navigation.module";
import region from  "./modules/region.module";
import language from  "./modules/language.module";
import flag from  "./modules/flag.module";
import category from  "./modules/category.module";
import film from  "./modules/film.module";
import entrust from "./modules/entrust.module";
import filmEpisode from  "./modules/film.episode.module";
import collection from "./modules/collection.module";
import subscribeMessageStock from "./modules/subscribeMessage.stock.module";
import user from "./modules/user.module";
import wallet from "./modules/wallet.module";
import membership from "./modules/membership.module";
import setting from "./modules/setting.module";
import commonModule from "./modules/common/common.module";
import wechatNews from "./modules/common/wechat.news.module";
import domainToIP from "./modules/common/domain2ip.module";
import platform from  "./modules/platform.module";
import platformWhiteList from  "./modules/platformWhiteList.module";
Vue.use( Vuex );

const getters = {};

const store = new Vuex.Store( {
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    navigation,
    region,
    language,
    flag,
    category,
    film,
    entrust,
    filmEpisode,
    collection,
    subscribeMessageStock,
    user,
    wallet,
    membership,
    setting,
    commonModule,
    wechatNews,
    domainToIP,
    platform,
    platformWhiteList
  },
  getters
} );

export default store;
