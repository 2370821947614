import { GetAction, PostAction, PutAction } from "@/store/actionHelper";

// [SETTING ACTIONS]
export const FIND_SETTING = 'findSetting'; // 查找ID对应的配置项
export const GET_SETTING_LIST = 'getSettingList'; // 获取所有的配置项
export const STORE_SETTING = 'storeSetting'; // 一个配置项
export const UPDATE_SETTING = 'updateSetting'; // 更新一个配置项
export const UPDATE_SETTING_LIST = 'updateSettingList'; // 更新所有的配置项
// [SETTING MUTATIONS]
export const SET_SETTING = 'setSetting'; // 设置一个setting state
export const SET_SETTING_ERROR = 'setSettingError'; // 设置一个setting state
export const SET_SETTING_LIST = 'setSettingList'; // 设置一个settingList state

const state = {
  setting: {},
  settings: [],
  settingError: null
};

const mutations = {
  [SET_SETTING](state, payload){
    state.setting = payload;
  },
  [SET_SETTING_LIST](state, payload){
    state.settings = payload;
  },
  [SET_SETTING_ERROR](state, payload){
    state.settingError = payload;
  },
};

const actions = {
  [GET_SETTING_LIST]( context ) {
    return GetAction( `manager/settings`,
    ``,
    context,
    SET_SETTING_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_SETTING_LIST, response.data );
      }
    } );
  },
  [UPDATE_SETTING_LIST]( context, payload = {} ) {
    return PutAction(
    "manager/settings/update-all", payload, context, SET_SETTING_ERROR,
    { credentials: true }
    );
  },
  [STORE_SETTING](context, formData = {}){
    return PostAction(
    "manager/settings", formData, context, SET_SETTING_ERROR,
      { credentials: true }
    );
  }
};

const getters = {
  setting: (state)=> state.setting, //
  settings: (state)=> state.settings, //
  settingError: (state)=> state.settingError
};

export default {
  state,
  mutations,
  actions,
  getters
}
