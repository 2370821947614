import LC from "./storage/localStorage";

import { ID_TOKEN_KEY } from "../store/types";

export const getToken = () => {
  return LC.get(ID_TOKEN_KEY);
};

export const saveToken = (token, expires_in = 55) => {
  LC.set(ID_TOKEN_KEY, token, expires_in);
};

export const destroyToken = () => {
  LC.destroy(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
