import { debugConsole } from "../helpers/helpers";

class Storage {

  constructor(driver = sessionStorage, prefix = 'storage_') {
    this.setStorage(driver);
    this._setKeyPrefix(prefix);
  }

  setStorage = (driver = localStorage)=>{
    if ( ![localStorage, sessionStorage].includes(driver) ){
      throw new Error('driver error!');
    }
    this.driver = driver;
    return this;
  }

  get = (key)=>{
    if ( this.hasExpired(key) ){
      return null;
    }
    let { data } = this._get(key);
    try {
      debugConsole({prefix: `${key.toUpperCase()}`, message: '所查询的键值对有效！'})
      return data;
    }catch ( e ) {
      debugConsole({prefix: `${key.toUpperCase()}`, message: '反序列化出错'})
      return '';
    }
  }

  set = (key, value, ttl = 1440)=> {
    let keyName = this._generateKey(key);
    let expiredTime = this._generateExpiredTime(ttl);
    this.driver.setItem(keyName, this._generateValue(value, expiredTime));
    debugConsole({prefix: key.toUpperCase(), message: '设置成功！'});
    return this;
  }

  has = (key)=> {
    return Boolean(this._get(key).expired_at);
  }

  hasExpired = (key)=>{
    if ( ! this.has(key) ){
      debugConsole({prefix: `${key.toUpperCase()}`, message: `缓存是否过期: 不存在`});
      debugConsole({prefix: `构造出来的Key:`, message: `${this._generateKey(key)}`});
      return true;
    }
    // 当前时间
    let current = Math.round(new Date().getTime() / 1000);
    // 已过期
    if (current > this._get(key).expired_at) {
      debugConsole({prefix: `${key.toUpperCase()}`, message: `缓存是否过期: 已过期`});
      debugConsole({prefix: `构造出来的Key:`, message: `${this._generateKey(key)}`});
      return true;
    }
    return false;
  }

  destroy = (key)=>{
    this.driver.removeItem(this._generateKey(key));
    return this;
  }

  expiredAtDiffInDays = (key)=>{
    let diffIn = this._diffIn(key, 'days');
    debugConsole({prefix: `${key.toUpperCase()}的剩余时间为`, message: `${diffIn} 天`});
    return diffIn;
  }

  expiredAtDiffInHours = (key)=>{
    let diffIn = this._diffIn(key, 'hours');
    debugConsole({prefix: `${key.toUpperCase()}的剩余时间为`, message: `${diffIn} 小时`});
    return diffIn;
  }

  expiredAtDiffInMinutes = (key)=>{
    let diffIn = this._diffIn(key, 'minutes');
    debugConsole({prefix: `${key.toUpperCase()}的剩余时间为`, message: `${diffIn} 分钟`});
    return diffIn;
  }

  expiredAtDiffInSeconds = (key)=>{
    let diffIn = this._diffIn(key, 'seconds');
    debugConsole({prefix: `${key.toUpperCase()}的剩余时间为`, message: `${diffIn} 秒`});
    return diffIn;
  }

  _diffIn = (key, diffType = 'hours')=>{
    if ( ! ['days', 'hours', 'minutes', 'seconds'].includes(diffType) ){
      throw new Error('diff type should in hours,minutes,seconds');
    }else if ( ! this.has(key) ){
      return false;
    }
    let { expired_at } = this._get(key);
    let current_time = Math.round(new Date().getTime() / 1000);
    let computeDiff = expired_at - current_time;
    switch ( diffType ) {
      case "days":
        return computeDiff / (60*60*24);
      case "hours":
        return computeDiff / (60*60);
      case "minutes":
        return computeDiff / (60);
      case "seconds":
        return computeDiff;
    }
  }

  _setKeyPrefix = (prefix)=>{
    this._keyPrefix = prefix;
    return this;
  }

  _getKeyPrefix = ()=>{
    return this._keyPrefix;
  }

  _generateKey = (key)=>{
    return this._getKeyPrefix() + key;
  }

  _generateExpiredTime = (minutes = 1)=> {
    let date = new Date();
    let seconds = 60000 * minutes;
    return Math.round((date.getTime() + seconds) / 1000);
  }

  _generateValue = (value, expiredTime)=>{
    return JSON.stringify({
      data: value,
      expired_at: expiredTime
    });
  }

  _get = (key)=>{
    return JSON.parse(this.driver.getItem(this._generateKey(key))) || {data: null, expired_at: null};
  }
}

export default Storage;
