import { GetAction, PostAction, UpdateAction } from "../actionHelper";

// [PLATFORM ACTIONS]
export const GET_PLATFORM_WHITE_LIST = 'getPlatformWhiteList'; //
export const GET_PLATFORM_WHITE_LISTS = 'getPlatformWhiteLists'; //
export const STORE_PLATFORM_WHITE_LIST = 'storePlatformWhiteList'; //
export const UPDATE_PLATFORM_WHITE_LIST = 'updatePlatformWhiteList'; //

// [PLATFORM MUTATIONS]
export const SET_PLATFORM_WHITE_LIST = 'setPlatformWhiteList'; //
export const SET_PLATFORM_WHITE_LISTS = 'setPlatformWhiteLists'; //
export const SET_PLATFORM_WHITE_LIST_ERROR = 'setPlatformWhiteListError'; //

const state = {
  platformWhiteList: {},
  platformWhiteLists: [],
  platformWhiteListsMeta: {},
  platformWhiteListError: null,
};

const mutations = {
  [SET_PLATFORM_WHITE_LIST](state, payload){
    state.platformWhiteList = payload;
  },
  [SET_PLATFORM_WHITE_LISTS](state, payload){
    state.platformWhiteLists = payload.data;
    state.platformWhiteListsMeta = payload.meta;
  },
  [SET_PLATFORM_WHITE_LIST_ERROR](state, payload){
    state.platformWhiteListError = payload;
  }
};

const actions = {
  [GET_PLATFORM_WHITE_LIST]( context, id ) {
    return GetAction( `manager/platforms-white-lists/${id}`,
    ``,
    context,
    SET_PLATFORM_WHITE_LIST_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_PLATFORM_WHITE_LIST, response.data );
      }
    } );
  },
  [GET_PLATFORM_WHITE_LISTS]( context, { page= 1, title= '' } ) {
    return GetAction( "manager/platforms-white-lists",
    `?title=${title || ''}&page=${page || 1}`,
    context,
    SET_PLATFORM_WHITE_LIST_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_PLATFORM_WHITE_LISTS, response.data );
      }
    } );
  },
  [STORE_PLATFORM_WHITE_LIST]( context, payload = {} ) {
    return PostAction(
    "manager/platforms-white-lists", payload, context, SET_PLATFORM_WHITE_LIST_ERROR,
    { credentials: true }
    );
  },
  [UPDATE_PLATFORM_WHITE_LIST]( context, payload = {} ) {
    return UpdateAction(
    "manager/platforms-white-lists", payload.id, payload, context, SET_PLATFORM_WHITE_LIST_ERROR,
    { credentials: true, resolved: ( context, response ) => { context.commit( SET_PLATFORM_WHITE_LIST, response.data );} }
    );
  },
};

const getters = {
  platformWhiteList: state=> state.platformWhiteList,
  platformWhiteLists: state=> state.platformWhiteLists,
  platformWhiteListsMeta: state=> state.platformWhiteListsMeta,
  platformWhiteListError: state=> state.platformWhiteListError,
};

export default {
  state,
  mutations,
  actions,
  getters
}
