import { GetAction, PostAction, UpdateAction, PutAction } from "../actionHelper";

// [NAVIGATION ACTIONS]
export const GET_CATEGORY = 'getCategory'; //
export const GET_CATEGORIES = 'getCategories'; //
export const STORE_CATEGORY = 'storeCategory'; //
export const UPDATE_CATEGORY = 'updateCategory'; //
export const SET_VISIBLE_FILMS = 'setVisibleFilms'; //
export const SET_HIDDEN_FILMS = 'setHiddenFilms'; //
export const SEARCH_CATEGORY = 'searchCategory'; //

// [NAVIGATION MUTATIONS]
export const SET_CATEGORY = 'setCategory'; //
export const SET_CATEGORIES = 'setCategories'; //
export const SET_CATEGORY_ERROR = 'setCategoryError'; //
export const SET_SEARCH_CATEGORY_LIST = 'setSearchCategoryList'; //

const state = {
  category: {},
  categories: [],
  categoriesMeta: {},
  categoryError: null,
  searchCategoryList: [],
  searchCategoryListMeta: {}
};

const mutations = {
  [SET_CATEGORY](state, payload){
    state.category = payload;
  },
  [SET_CATEGORIES](state, payload){
    state.categories = payload.data;
    state.categoriesMeta = payload.meta;
  },
  [SET_CATEGORY_ERROR](state, payload){
    state.categoryError = payload;
  },
  [SET_SEARCH_CATEGORY_LIST](state, payload){
    state.searchCategoryList = payload.data;
    state.searchCategoryListMeta = payload.meta || {};
  },
};

const actions = {
  [GET_CATEGORY]( context, id ) {
    return GetAction( `manager/films/categories/${id}`,
    ``,
    context,
    SET_CATEGORY_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_CATEGORY, response.data );
      }
    } );
  },
  [GET_CATEGORIES]( context, { page= 1, title= '' } ) {
    return GetAction( "manager/films/categories",
    `?title=${title || ''}&page=${page || 1}`,
    context,
    SET_CATEGORY_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_CATEGORIES, response.data );
      }
    } );
  },
  [STORE_CATEGORY]( context, payload = {} ) {
    return PostAction(
    "manager/films/categories", payload, context, SET_CATEGORY_ERROR,
    { credentials: true }
    );
  },
  [UPDATE_CATEGORY]( context, payload = {} ) {
    return UpdateAction(
    "manager/films/categories", payload.id, payload, context, SET_CATEGORY_ERROR,
    { credentials: true, resolved: ( context, response ) => { context.commit( SET_CATEGORY, response.data );} }
    );
  },
  [SET_VISIBLE_FILMS]( context, {id, column} ) {
    return PutAction(
    `manager/films/categories/${id}/set-films-visible`, column, context, SET_CATEGORY_ERROR,
      { credentials: true }
    );
  },
  [SET_HIDDEN_FILMS]( context, {id, column} ) {
    return PutAction(
    `manager/films/categories/${id}/set-films-hidden`, column, context, SET_CATEGORY_ERROR,
    { credentials: true }
    );
  },
  [SEARCH_CATEGORY]( context, { title = '', page = false } ) {
    return GetAction( "manager/films/categories/search",
    `?title=${title || ''}${page ? '&page=' + page : ''}${page ? '&paginate=' + page : ''}`,
    context,
    SET_CATEGORY_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        let payload = page ? response.data : response;
        context.commit( SET_SEARCH_CATEGORY_LIST, payload );
      }
    } );
  },
};

const getters = {
  category: state=> state.category,
  categories: state=> state.categories,
  categoriesMeta: state=> state.categoriesMeta,
  categoryError: state=> state.categoryError,
  searchCategoryList: state=> state.searchCategoryList,
  searchCategoryListMeta: state=> state.searchCategoryListMeta,
};

export default {
  state,
  mutations,
  actions,
  getters
}
