import router from "../router";
import { PURGE_AUTH } from "../store/modules/auth.module";

const handleReject = (context, response, mutation)=>{
  let {data, status} = response.response || {};
  let { name } = router.currentRoute;
  if ( status === 401 && name !== 'login' ){
    context.commit(PURGE_AUTH);
    return router.replace({name: 'login'});
  }
  let errors;
  if ( typeof data == 'object' ){
    errors = data.errors || data.message;
  }else if ( typeof data === 'string' ){
    errors = data;
  }
  context.commit(mutation, errors);
};

export default handleReject;
